import React from "react";
import { MDBAccordion, MDBAccordionItem, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText } from "mdb-react-ui-kit";
import Header from "../utils/Header";
import { Helmet } from 'react-helmet';

class Responsibility extends React.Component {

    render() {
        return (
            < div className='p-1 mb-4' >
                 <Helmet>
                    <title>Accelerating Sustainability with AI</title>
                    <meta name="description" content="Accelerating Sustainability with AI" />
                    <meta name="keywords" content="Artificial Intelligence Product Categories" />
                    <meta name="author" content="AVision" />
                    <meta property="og:title" content="Artificial Intelligence Product Categories" />
                    <meta property="og:description" content="Accelerating Sustainability with AI" />
                    <meta property="og:image" content="https://example.com/image.jpg" />
                    <meta property="og:url" content="https://example.com/my-page" />
                    <meta name="twitter:title" content="Artificial Intelligence Product Categories" />
                    <meta name="twitter:description" content="Accelerating Sustainability with AI" />
                    <meta name="twitter:image" content="https://example.com/image.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Header
                    url={"url('/images/header_main.png"}
                    header={"Responsible use of Artifical Intelligence"}
                    desc={"Crafting Tomorrow Responsibly: Ethical AI for a Better World"}
                />
                <MDBRow>
                    <MDBCol size='md-3'>
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">Accelerating Sustainability with AI</p></MDBCardTitle>
                                <MDBCardText>
                                    Given the urgency of the planetary crisis, society needs to push harder on the AI accelerator while establishing guardrails that steer the world safely, securely, and equitably toward net-zero emissions, climate resilience, and a nature-positive future.
                                    <br /><br />
                                    This year the world experienced the impacts of climate change like never before, from devastating wildfires to extreme weather. We are seeing and feeling the impact of climate change in our communities every day, and the science is clear: we need to act at an unprecedented scale and pace to address this crisis. It’s an enormous challenge and an enormous opportunity for the world to accelerate climate progress.
                                    <br /><br />
                                    At AVision Softwaree, we believe that for our company to do well, the world also needs to do well. We are at a critical moment for environmental sustainability, and we need government leaders, businesses, and civil society working in tandem. We also need to use every tool at our disposal to aid us in this journey, including AI.
                                    <br /><br />
                                    AI is a vital tool to help accelerate the deployment of existing sustainability solutions and the development of new ones—faster, cheaper, and better.
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">Cultivate Trust with Secure and Responsible AI</p></MDBCardTitle>
                                <MDBCardText>

                                    AI can be a revolutionary tool for business, but its power must be founded on trust. One part of that trust is built on stakeholders inside and outside
                                    of your business knowing that your AI tools are ethically developed and responsibly deployed. The other part of building trust in AI arises from
                                    it being secure—that is, knowing that both the potentially sensitive data that powers AI is secure from breach and that the AI tools themselves are not compromised by malicious actors.
                                    <br />
                                    <p className="header-3">Using AI Responsibly</p>

                                    Leaders from companies across industries want to be ready for AI. Research conducted by Microsoft indicates that more than 64 percent of business leaders surveyed believe that AI will have a positive impact on leadership, and more than 67 percent of leaders report that they would support changing their skill emphasis so that they’re better prepared for the age of AI.5
                                    In order to fully harness the benefits of AI, business leaders and other stakeholders must trust AI. And fundamental to trust in AI is using AI responsibly. Microsoft has demonstrated leadership and delivered powerful tooling to help build safety
                                    and responsibility into AI solutions from the start.
                                    <br /><br />
                                    This includes Responsible AI Principles and the product capabilities developed to help customers build AI safety into their solutions. Microsoft has committed to ensuring trust, security, and privacy in the era of AI by establishing a core set of six principles:
                                    <br /><br />
                                    • Fairness<br />
                                    • Reliability and safety<br />
                                    • Privacy and security<br />
                                    • Inclusiveness<br />
                                    • Transparency<br />
                                    • Accountability<br /><br />
                                    These principles are vital both for developing AI technologies and for implementing them in business operations.
                                    Microsoft is committed to security, privacy, and compliance across everything it does, and its approach to AI is no different. Microsoft published the Microsoft Responsible AI Standard based on years of AI development and implementation
                                    to help every organization use AI responsibly. Microsoft can help every organization build safety and responsibility into their AI journeys from
                                    the very beginning.
                                    <br />

                                    <br />
                                    By analyzing patterns
                                    and anomalies in large datasets, businesses can make use of generative models to detect and prevent fraud, safeguard sensitive information, and protect their digital assets. For example, in financial services, businesses can protect loan and credit privacy with sharable data, detect suspicious and fraudulent transactions, and turn data trends into risk assessments.
                                    AI solutions like Microsoft Security Copilot can work seamlessly with security teams. This solution can synthesize data from multiple sources into clear, actionable insights to simplify incident responses, triage signals faster, and surface
                                    threats earlier. Solutions like Security Copilot can empower defenders to see what is happening in their environments, learn from existing intelligence, correlate threat activities, and make more informed, effective decisions at machine speed.
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size="md-3">
                        <MDBAccordion initialActive={1}>
                            <MDBAccordionItem collapseId={1} headerTitle={<p className="header-2">Securing Your Business</p>}>
                                The costs of security breaches continue to mount for businesses. Organizations that use AI security and automation tools reported breach-related costs $1.76 million lower than organizations that do not use them, in addition to taking an average of 108 fewer days to identify and contain breaches.6
                                The costs to companies from cybercrime can extend beyond data breaches, including fraud
                                and other forms of theft.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={2} headerTitle={<p className="header-2">Threat Detection and Response</p>}>
                            AI systems can analyze vast amounts of data in real-time to identify patterns and detect anomalies that may indicate a cyber threat. Unlike traditional systems, AI can adapt to new and evolving threats more quickly, enabling faster detection and more immediate response to incidents such as malware attacks, phishing, and insider threats.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={3} headerTitle={<p className="header-2">Predictive Analytics</p>}>
                            By analyzing historical data, AI can predict future security incidents before they occur, allowing organizations to proactively strengthen their defenses and mitigate potential risks.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={4} headerTitle={<p className="header-2">Automated Security Protocols:</p>}>
                            AI can automate routine security tasks, such as patch management and vulnerability assessments, reducing the workload on human security teams and minimizing the risk of human error.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={5} headerTitle={<p className="header-2">Enhanced Authentication</p>}>
                            AI-driven biometric authentication methods, such as facial recognition and voice recognition, offer more secure and user-friendly alternatives to traditional passwords and PINs.
                            </MDBAccordionItem>
                        </MDBAccordion>
                    </MDBCol>
                </MDBRow>
            </div >
        )
    }
}

export default Responsibility;