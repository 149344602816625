import { MDBBtn } from "mdb-react-ui-kit";
import React from 'react';
import { Props } from "../types/props";

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
declare var toggle: any;

export default function Header(props: Props) {

    return (
        <div
            className='p-5 text-center bg-image'
            style={{ backgroundImage: props.url, height: '600px',marginBottom: '5px' }}
        >
            <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <div className='text-white'>
                        <h1 className='mb-3'>{props.header}</h1>
                        <h4 className='mb-3'>{props.desc}</h4>
                        <MDBBtn tag="a" outline size="lg" onClick={toggle}>
                            Chat Now
                        </MDBBtn>
                    </div>
                </div>
            </div>
        </div>

    )
}
