import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Props } from "../types/props";

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
declare var toggle: any;

export default function Header(props: Props) {

    return (
        <div className="text-center bg-image">
            <MDBRow className='d-flex justify-content-center'>
                <MDBCol size='md-12'>
                    <video
                        style={{ height: '110vh'}}
                        playsInline
                        autoPlay
                        muted
                        loop
                    >
                        <source
                            className="h-25"
                            src="/images/header-2.mp4"
                            type="video/mp4"
                        />
                    </video>
                    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div className='text-white'>
                                <h1 className='header-main-1'>{props.header}</h1>
                                <h4 className='header-main'>{props.desc}</h4>
                                <MDBBtn tag="a" outline size="lg" onClick={toggle}>
                                    Begin Today
                                </MDBBtn>
                            </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
        </div>

    )
}
