import { MDBAccordion, MDBAccordionItem, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from 'react';
import { Helmet } from 'react-helmet';
import Header from "../utils/Header";

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class Technology extends React.Component {

    render() {
        return (
            < div className='p-1 mb-4' >
                <Helmet>
                    <title>Artificial Intelligence as a Service</title>
                    <meta name="description" content="Artificial Intelligence as a Service" />
                    <meta name="keywords" content="Artificial Intelligence as a Service" />
                    <meta name="author" content="AVision" />
                    <meta property="og:title" content="Artificial Intelligence as a Service" />
                    <meta property="og:description" content="Artificial Intelligence as a Service" />
                    <meta property="og:image" content="https://example.com/image.jpg" />
                    <meta property="og:url" content="https://example.com/my-page" />
                    <meta name="twitter:title" content="Artificial Intelligence as a Service" />
                    <meta name="twitter:description" content="Artificial Intelligence as a Service" />
                    <meta name="twitter:image" content="https://example.com/image.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Header
                    url={"url('/images/header_main.png"}
                    header={"Artificial Intelligence as a Service"}
                    desc={"Empower Your Innovation, On-Demand with AIaaS"}
                />
                <MDBRow>
                    <MDBCol size='md-3'>
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">Machine Learning</p></MDBCardTitle>
                                <MDBCardText>
                                    A subset of AI and computer science where algorithmic models are trained to learn from existing data to make decisions or predictions
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <hr className="hr" />
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">Deep Learning</p></MDBCardTitle>
                                <MDBCardText>
                                    A machine learning technique that uses layers of neural networks to process data and make decisions
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <hr className="hr" />
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">Generative AI</p></MDBCardTitle>
                                <MDBCardText>
                                    A type of AI technology that uses algorithmic models to create new written, visual and auditory content when given prompts or existing data
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">Artificial Intelligence as a Service</p></MDBCardTitle>
                                <MDBCardText>
                                    Artificial Intelligence as a Service (AIaaS) refers to the outsourcing of artificial intelligence (AI) services. It encompasses a wide range of AI capabilities, including machine learning, deep learning, natural language processing, and computer vision, provided through the cloud. Companies and developers can access these services without the need to invest heavily in their own AI infrastructure or expertise. This model allows businesses to integrate AI functionalities into their operations, applications, or products more quickly and cost-effectively.

                                    AIaaS operates similarly to other "as a service" models, such as Software as a Service (SaaS), Platform as a Service (PaaS), and Infrastructure as a Service (IaaS). It provides users with access to AI tools and technologies on a subscription basis or a pay-per-use basis, reducing the barriers to entry for utilizing advanced AI technologies.

                                    Key benefits of AIaaS include:

                                    <p className="header-3">Cost Efficiency</p> It eliminates the need for significant upfront investment in hardware and software for AI capabilities.
                                    <p className="header-3">Scalability</p>Businesses can scale their AI solutions up or down based on their current needs, without worrying about capacity planning.
                                    <p className="header-3">Accessibility</p>Even small and medium-sized enterprises (SMEs) can access cutting-edge AI technologies, leveling the playing field between them and larger corporations.
                                    <p className="header-3">Expertise</p>Service providers often offer expertise and support, helping businesses to implement AI solutions effectively.
                                    <p className="header-3">Speed to Market</p>Companies can deploy AI functionalities faster, as the infrastructure and tools are already in place.
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size="md-3">
                        <MDBAccordion initialActive={1}>
                            <MDBAccordionItem collapseId={1} headerTitle={<p className="header-2">Generate New Products and Services</p>}>
                                Leaders are creating tailored AI models to solve specific industry challenges, such as fraud detection, sustainable retail, and manufacturing efficiency.
                                By harnessing AI, organizations can not only enhance existing offerings but also introduce groundbreaking products and services, which can help drive both innovation and business growth.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={2} headerTitle={<p className="header-2">GPT-4</p>}>
                                A set of models that improve on GPT-3.5 and can understand and generate natural language and code
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={3} headerTitle={<p className="header-2">Embeddings</p>}>
                                A set of models that can convert text into numerical vector form to facilitate text similarity
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={4} headerTitle={<p className="header-2">DALL-E</p>}>
                                A series of models in preview that can generate original images from natural language.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={5} headerTitle={<p className="header-2">Whisper</p>}>
                                A series of models in preview that can transcribe and translate speech to text.
                            </MDBAccordionItem>
                        </MDBAccordion>
                    </MDBCol>
                </MDBRow>
            </div >
        )
    }
}

export default Technology;