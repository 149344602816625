import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardText, MDBCardTitle, MDBCarousel, MDBCarouselCaption, MDBCarouselItem, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React from 'react';
import Header2 from "../utils/Header2";
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class BodyMain extends React.Component {

    render() {
        return (

            <div className='p-1 mb-4'>
                <Helmet>
                    <title>AVision Software: Realize Value with AI</title>
                    <meta name="description" content="AVision Software: Realize Value with AI" />
                    <meta name="keywords" content="AVision Software: Realize Value with AI" />
                    <meta name="author" content="AVision" />
                    <meta property="og:title" content="AVision Software: Realize Value with AI" />
                    <meta property="og:description" content="AVision Software: Realize Value with AI" />
                    <meta property="og:image" content="https://example.com/image.jpg" />
                    <meta property="og:url" content="https://example.com/my-page" />
                    <meta name="twitter:title" content="AVision Software: Realize Value with AI" />
                    <meta name="twitter:description" content="AVision Software: Realize Value with AI" />
                    <meta name="twitter:image" content="https://example.com/image.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Header2
                    url={"url('/images/header_main.png"}
                    header={"Welcome to AVision Software"}
                    desc={"Your gateway to the future of Artificial Intelligence"}
                />
                <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size='md-8'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle>
                                    <p className="header-1">At AVision Software, we don't just create AI - we create possibilities</p>
                                </MDBCardTitle>
                                <MDBCardText className="header-text-align font-color">
                                    <hr className="hr" />
                                    <h5>We're at the forefront of the AI revolution, committed to developing state-of-the-art software solutions that redefine efficiency, creativity, and decision-making.
                                        Our technology is designed to enhance human potential and empower organizations across various industries. </h5>
                                    <h5>Our approach is grounded in ethical principles, ensuring that our advancements contribute positively to society and the environment.</h5>
                                    <h5>Join us on this journey as we explore the endless possibilities of AI technology, shaping a future where technology amplifies human capability and creativity.</h5>
                                    <hr className="hr" />
                                </MDBCardText>
                                < MDBCardTitle>
                                    <p className="header-1">AI provides an array of capabilities, these encompass four broad categories:</p>
                                </MDBCardTitle>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCarousel showIndicators showControls fade>
                            <MDBCarouselItem itemId={1}>
                                <img src='/images/ai-vision.jpeg' className='d-block w-100' alt='...' />
                                <MDBCarouselCaption>
                                    <h5>Vision Capabilities</h5>
                                    <p>Vision capabilities range from image analysis to facial recognition and optical character recognition (OCR), but they also include spatial analysis of people’s presence and movements, in addition to content discoverability to classify and caption images.</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={2}>
                                <img src='/images/ai-voice.jpeg' className='d-block w-100' alt='...' />
                                <MDBCarouselCaption>
                                    <h5>Speech Capabilities</h5>
                                    <p>Speech capabilities include well-known uses such as speech-to-text and text-to-speech, but they can also recognize the intent of people independent of their exact wording, automate video captioning, and create audio content.</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={3}>
                                <img src='/images/ai-language.jpg' className='d-block w-100' alt='...' />
                                <MDBCarouselCaption>
                                    <h5>Language capabilities</h5>
                                    <p>Language capabilities extend to not just translation but also sentiment analysis, key- phrase extraction, and opinion mining. OpenAI—the AI research organization behind well-known AI models like ChatGPT—is a good representation of the natural and intuitive AI interface that natural language provides.</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={3}>
                                <img src='/images/ai-decision.jpg' className='d-block w-100' alt='...' />
                                <MDBCarouselCaption className='carousel-decision'>
                                    <div className="bg-text">
                                        <h5>Decision capabilities </h5>
                                        <p>Decision capabilities include everything from anomaly detection to automatic content personalization to automated content moderation for text, image, and video applications. Machine learning and advanced analytics are good examples of this domain.</p>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                        </MDBCarousel>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-1">Realise Value with Artificial Intelligence</p></MDBCardTitle>
                                <hr className="hr" />
                                <MDBCardText className="header-text-align font-color">
                                    <h5>In the modern business landscape, creating value extends beyond mere profits. Whether by aiding the medical field in pioneering research and diagnostics, enhancing software development
                                        with AI copilots, or guiding retail customers with personalized recommendations, AI is instrumental in reshaping industries.</h5>
                                    <h5> It stands as a beacon, driving innovation, fostering sustainable growth, and setting the trajectory for businesses in a rapidly evolving marketplace.</h5>
                                    <h5>AI-driven personalization doesn't just improve sales; it fundamentally enhances the entire customer experience. From guiding patients to specialists to directing customers toward suitable financial products, AI ensures that businesses meet precise customer needs. </h5>
                                    <hr className="hr" />
                                    <h5>Beyond this, virtual assistants and modern call centers—powered by AI—redefine customer interaction by offering deeper and more relevant engagements, thus transforming the landscape of customer service.
                                        Accurate, timely, and actionable reporting is essential for informed decision-making. In manufacturing, AI elevates this process by autonomously collecting and presenting vital data and by offering insights, trends, and intuitive discovery mechanisms. Instead of sifting through databases, executives can query advanced
                                        AI systems to enable proactive responses to challenges, fine-tune strategies, and maintain a competitive edge in dynamic markets.</h5>
                                </MDBCardText>
                                <MDBCardFooter className="footer-align">
                                    <NavLink to='https://outlook.office.com/bookwithme/user/fff8cf66d34b4a059a366baa2e6cfa5d@avisionsoftware.com?anonymous&ep=plink' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        <MDBBtn tag="a" outline size="lg" >
                                        <MDBIcon fab icon="microsoft" /> Book Discovery Call
                                        </MDBBtn>
                                    </NavLink>
                                    <NavLink to='https://calendar.app.google/BpzU82fU682kAMX76' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        <MDBBtn tag="a" outline size="lg" >
                                        <MDBIcon fab icon="google" />  Book Discovery Call
                                        </MDBBtn>
                                    </NavLink>
                                </MDBCardFooter>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}

export default BodyMain;